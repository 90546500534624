<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           :title="isAdd?'新建卡片封面':'编辑卡片封面'"
           :afterClose="cancel"
           width="550px"
           @cancel="cancel"
           @ok="handleComplete">
    <div class="p-10"> 
      <a-form-model layout="inline"
        :labelCol="{ span: 6 }"
        :wrapperCol="{ span: 18 }">
        <div class="flex content-class">
          <a-form-model-item prop="cardInfo.image_path" label="图片">
            <upload-image type="35" :value.sync="cardInfo.image_path" />
            <!-- <a-button v-if="image_path" style="position: absolute;right:0px;top:30px;"
                  type="link" @click="handlerPreview">预览</a-button> -->
          </a-form-model-item>
          <a-form-model-item prop="cardInfo.card_name" label="名称">
            <a-input v-model="cardInfo.card_name" placeholder="名称"></a-input>
          </a-form-model-item>
        </div>
      </a-form-model>
    </div>

  </a-modal>

</template>

<script>
import UploadImage from "@/components/upload-image"

export default {
  components: { UploadImage },

  props: {
    show: {
      type: Boolean,
      default: false
    },
    isAdd: {
      type: Boolean,
      default: false
    },
    cardInfo: {
      type: Object,
      default: ()=>{}
    }
  },
  data () {
    return {

    }
  },
  created(){
    if(!this.cardInfo){
      this.cardInfo = {
        card_id: 0
      }
    }
  },
  async mounted () {

  },
  methods: {
    cancel () {
      this.$emit('update:show', false)
    },

    handleComplete(){
      if(!this.cardInfo.card_name){
        this.$message.warning('请填写名称')
        return
      }
      if(!this.cardInfo.image_path){
        this.$message.warning('请上传封面图')
        return
      }
      this.$emit('update:show', false)
      this.$emit('ok', this.cardInfo)
    }
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>