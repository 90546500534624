<template>
  <a-modal :visible="show"
           v-bind="$attrs"
           title="编辑分类"
           :afterClose="remove"
           width="550px"
           @cancel="remove"
           @ok="handleOk">
    <div>
      <ul class="type-table-list">
        <li class="type-table-list__item">
          <div class="flex type-table-list__item-span font-bold">
            <div style="width:80px;">分类名称</div>
            <div class="flex-1 mr-2">
              中文名称
            </div>
            <div class="flex-1 mr-3">
              英文名称
            </div>
            <div style="width:80px;">
              操作
            </div>
          </div>
        </li>
        <div id="type-table-list">
          <li class="type-table-list__item"
              v-for="(item,i) in newTypeList"
              :key="item.type_id">
            <div class="flex type-table-list__item-span">
              <span style="min-width:80px;">{{item.type_name}}</span>
              <div class="flex-1 mr-2">
                <a-input :maxLength="8" v-model="item.type_name" />
              </div>
              <div class="flex-1 mr-3">
                <a-input :maxLength="20" v-model="item.type_name_en" />
              </div>
              <div style="width:80px;">
                <a-button type="link" class="p-0"
                          @click="onTypeDelete(i)">删除</a-button>
              </div>
            </div>
          </li>
        </div>
      </ul>
      <div>
        <span style="width:60px;"> </span>
        <a-button type="link"
                  icon="plus"
                  @click="onTypeAdd">新增分类</a-button>
      </div>
    </div>

  </a-modal>

</template>

<script>
import _ from 'lodash'
import Sortable from 'sortablejs'

import {
  updateGiftType
} from "@/api/activity/vip-card.js"

export default {
  props: {
    show: {
      type: Boolean,
      default: false
    },
    typeList: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      newTypeList: []
    }
  },
  async mounted () {
    this.newTypeList = _.cloneDeep(this.typeList)
    await this.$nextTick()
    this.rowDrop()
  },
  methods: {
    remove () {
      this.$emit('update:show', false)
    },
    handleOk () {
      const _this = this
      this.$confirm({
        title: '提示',
        content: '是否确认更新当前分类内容?',
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk () {
          updateGiftType({ type_data: JSON.stringify(_this.newTypeList) }).then(res => {
            if (res.code === 0) {
              _this.$message.success('编辑成功！')
              _this.$emit('update:show', false)
              _this.$emit('ok', _this.newTypeList)
            }
          })
          
        },
        onCancel () {
          console.log('Cancel');
        },
      });

    },
    onTypeDelete (e) {
      this.newTypeList.splice(e, 1)
    },
    onTypeAdd () {
      this.newTypeList.push({
        type_id: 0,
        type_name: ''
      })
    },
    //行拖拽
    rowDrop () {
      var _this = this
      var $ul = document.getElementById('type-table-list')
      new Sortable($ul, {
        onUpdate: function (event) {
          //修改items数据顺序
          var newIndex = event.newIndex,
            oldIndex = event.oldIndex,
            $li = $ul.children[newIndex],
            $oldLi = $ul.children[oldIndex]
          // 先删除移动的节点
          $ul.removeChild($li)
          // 再插入移动的节点到原有节点，还原了移动的操作
          if (newIndex > oldIndex) {
            $ul.insertBefore($li, $oldLi)
          } else {
            $ul.insertBefore($li, $oldLi.nextSibling)
          }
          // 更新items数组
          const item = _this.newTypeList.splice(oldIndex, 1)
          _this.newTypeList.splice(newIndex, 0, item[0])
          // 下一个tick就会走patch更新
        },
        animation: 150
      })
    }
  }
}
</script>

<style>
.type-table-list__item-span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
</style>