<template>
  <div>
    <base-table ref="listTable"
                id="listTable"
                :rowKey="giftRowKey"
                :columnsData="columns"
                :tableData="tableData"
                :addHeight="-35">
      <template #toolbar>
        <div>
          <h4 class="text-lg font-bold">钱包礼品卡</h4>
          <div class="flex justify-between">
            <a-tabs type="card"
                    @change="handlerTypeChange"
                    :activeKey="activeKey">
              <a-tab-pane v-for="item in typeList"
                          :key="item.type_id"
                          :tab="item.type_name"></a-tab-pane>
              <div slot="tabBarExtraContent">
                <a-button type="primary"
                          @click="handlerEditType"
                          html-type="submit"
                          style="margin-left:15px;">编辑分类</a-button>
              </div>
            </a-tabs>
            <div>
              <template v-if="isEdit">
                <a-button type="primary"
                          @click="handlerAddCard"
                          html-type="submit">新增卡片</a-button>
                <a-button class="ml-3"
                          v-if="isEdit"
                          type="danger"
                          @click="handlerEditCardsSave"
                          html-type="submit">保存</a-button>
              </template>
              <a-button v-if="!isEdit" type="primary"
                        @click="showEditMode"
                        html-type="submit">编辑</a-button>
              <a-button v-else class="ml-6"
                        type="dashed"
                        @click="handlerEditGoodsCancel"
                        html-type="submit">取消</a-button>
            </div>
          </div>
        </div>
      </template>

      <template #image_path="{ text }">
        <img :src="imgDomain+text+cutQuery(116, 73)"
             style="height: 73px" />
      </template>
      <template #operation="{ record, index }">
        <a-button type="link"
                  @click="handlerEditCard(record)">编辑</a-button>
        <a-button type="link" class="ml-3"
                  @click="handlerDelete(index)">删除</a-button>
      </template>
    </base-table>

    <type-edit-modal v-if="isShowType"
                     :show.sync="isShowType"
                     :typeList="typeList"
                     @ok="handlerEditTypeOk" />
    <card-edit-modal v-if="isShowEditCard" 
                    :show.sync="isShowEditCard"
                    :typeId="activeKey"
                    :isAdd="isAddMode"
                    :cardInfo="activeCard"
                    @ok="handlerAddCardOk" />
  </div>
</template>

<script>
import Sortable from "sortablejs"

import TypeEditModal from "./components/type-edit-modal.vue"
import CardEditModal from "./components/card-edit-modal.vue"

import { giftColumns, giftRowKey } from "./columns"
import { cutQuery } from '@/utils/ossImg'

import {
  getGiftTypeList,
  // updateGiftType,
  getGiftCardList,
  updateGiftCard
} from "@/api/activity/vip-card.js"

export default {
  components: {
    TypeEditModal,
    CardEditModal
  },
  data () {
    return {
      giftRowKey,
      giftColumns,
      isEdit: false,
      isShowType: false,
      activeKey: 0,
      typeList: [],
      // total: 0,
      imgDomain: "",
      tableData: [],
      columns: [],
      isShowEditCard: false,
      isAddMode: false,
      activeCard: null,

      sortable: null
    }
  },
  async mounted () {
    this.columns = giftColumns.filter(item=>{
      return !["operation"].includes(item.dataIndex)
    })
    this.initData()
  },
  methods: {
    cutQuery,
    async initData () {
      await this.initGiftType()

      if(this.typeList.length){
        this.handlerTypeChange(this.typeList[0].type_id)
      }
    },
    async initGiftType(){
      const { data, code } = await getGiftTypeList()
      if (code == 0) {
        this.typeList = data.list
      }
    },
    async initCardsData () {
      this.hideEditMode()

      const res = await getGiftCardList({ type_id: this.activeKey })
      this.imgDomain = res.data.domain_name
      this.tableData = res.data.list.map(el=>{
        el.ran_id = el.card_id
        return el
      })
    },

    handlerAddCard () {
      this.activeCard = null
      this.isAddMode = true
      this.isShowEditCard = true
    },
    handlerAddCardOk(data){
      if(!data.ran_id){
        data.card_id = data.card_id || 0
        data.ran_id = Math.round(Math.random()*1000000)
        let list = this.tableData
            list.push(data)
        this.tableData = list
      }
    },

    handlerEditCard(card){
      this.activeCard = card
      this.isAddMode = false
      this.isShowEditCard = true
    },

    handlerTypeChange (type_id) {
      this.activeKey = type_id
      this.initCardsData()
    },

    // 分类ok
    handlerEditTypeOk () {
      this.initGiftType()
    },

    handlerEditCardsSave () {
      let cards = this.tableData
      const params = { 
        type_id: this.activeKey,
        card_data: JSON.stringify(cards)
      }

      updateGiftCard(params).then((res) => {
        if (res.code === 0) {
          this.$message.success("保存成功")
          this.initCardsData()
        }
      })
    },
    handlerEditGoodsCancel () {
      this.initCardsData()
    },

    handlerDelete (index) {
      this.tableData.splice(index, 1)
    },

    showEditMode(){
      this.isEdit = true
      this.columns = giftColumns
      if(!this.sortable){
        this.rowDrop()
      }
    },
    hideEditMode(){
      this.isEdit = false
      this.columns = giftColumns.filter(item=>{
        return !["operation"].includes(item.dataIndex)
      })
      if(this.sortable) {
        this.sortable.destroy()
        this.sortable = null
      }
    },

    handlerEditType () {
      this.isShowType = true
    },
    //行拖拽
    rowDrop () {
      const tbody = document.querySelector(
        "#listTable .ant-table .ant-table-body .ant-table-tbody"
      )
      const _this = this
      this.sortable = Sortable.create(tbody, {
        group: ".ant-table-row",
        handle: ".ant-table-row",
        onEnd ({ newIndex, oldIndex }) {
          const currRow = _this.tableData.splice(oldIndex, 1)[0]
          _this.tableData.splice(newIndex, 0, currRow)
        },
      })
    },
  },
}
</script>

<style lang="less">
.goods-search-bar {
  margin-bottom: 16px;
  padding: 15px 24px 0;
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  .ant-form-item {
    margin-bottom: 5px;
  }
}
</style>
