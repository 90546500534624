export const normalRowKey = "id";
export const normalColumns = [
  {
    title: "套餐名称",
    dataIndex: "name",
    align: "center",
    width: 200,
  },
  {
    title: "覆盖区域",
    align: "center",
    dataIndex: "delivery_region",
  },
  {
    title: "充值面额",
    align: "center",
    dataIndex: "start_time",
  },
  {
    title: "售价",
    align: "center",
    dataIndex: "end_time",
  },
  {
    title: "赠送积分",
    align: "center",
    dataIndex: "status",
  },
  {
    title: "赠送优惠券",
    align: "center",
    dataIndex: "status1",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 200,
    slots: {
      customRender: "operation",
    },
  },
];


export const areaRowKey = "id";
export const areaColumns = [
  {
    title: "套餐名称",
    dataIndex: "name",
    align: "center",
    width: 200,
  },
  {
    title: "覆盖区域",
    align: "center",
    dataIndex: "delivery_region",
  },
  {
    title: "充值面额",
    align: "center",
    dataIndex: "start_time",
  },
  {
    title: "售价",
    align: "center",
    dataIndex: "end_time",
  },
  {
    title: "赠送积分",
    align: "center",
    dataIndex: "status",
  },
  {
    title: "赠送优惠券",
    align: "center",
    dataIndex: "status1",
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 200,
    slots: {
      customRender: "operation",
    },
  },
]


export const giftRowKey = "ran_id";
export const giftColumns = [ // 会员卡充值卡
  {
    title: '封面图',
    dataIndex: 'image_path',
    align: 'center',
    width: 200,
    slots: { customRender: 'image_path' }
  },
  {
    title: '名称',
    dataIndex: 'card_name',
    align: 'center',
  },
  {
    title: '分类',
    dataIndex: 'type_name',
    align: 'center',
  },
  {
    title: '操作',
    dataIndex: 'operation',
    align: 'center',
    width: 150,
    slots: { customRender: 'operation' }
  },
]

export default {
  normalRowKey,
  normalColumns,
  areaRowKey,
  areaColumns,
  giftRowKey,
  giftColumns
};
